import {
  mapActions, mapGetters,
} from 'vuex';

import {
  Sidebar,
  Challenge,
} from '@/components';

export default {
  metaInfo: {
    title: 'Uitdagingen - IKwatersport',
  },
  name: 'Challenges',
  components: {
    [Sidebar.name]: Sidebar,
    [Challenge.name]: Challenge,
  },
  props: {},
  data: () => ({

  }),
  computed: {
    ...mapGetters('challenge', {
      challengeList: 'getChallengeList',
    }),
  },
  methods: {
    ...mapActions('challenge', {
      initialiseChallengeList: 'FETCH_DATA_CHALLENGE_LIST',
    }),
  },
  created() {
    this.initialiseChallengeList().then(() => {
    });
  },
};
