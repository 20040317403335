import { render, staticRenderFns } from "./Challenges.vue?vue&type=template&id=c294b704&scoped=true&"
import script from "./Challenges.controller.js?vue&type=script&lang=js&"
export * from "./Challenges.controller.js?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Challenges.styles.scss?vue&type=style&index=0&id=c294b704&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "c294b704",
  "7a918c38"
  
)

export default component.exports